<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="customizeIcon" alt="" />
            </div>
            <div class="plugin-name">
              <p>Custom Plugin</p>
              <span
                >Connect an OpenAI Plugin to expose Chat KeyReply to third-party applications.</span
              >
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <div>
          <p style="font-size: 24px; margin-top: 0"><b>Enter your website domain</b></p>
        </div>
        <div class="plugin-modal-configuration">
          <div>
            <p>
              To connect a plugin, provide the website domain where your
              <a
                target="_blank"
                style="color: blue; text-decoration: underline"
                href="https://platform.openai.com/docs/plugins/getting-started/plugin-manifest"
                >OpenAI plugin manifest</a
              >
              is hosted. This is the <b>ai-plugin.json</b> file.
            </p>
          </div>
          <div>
            <el-input placeholder="yourdomain.com" v-model="websiteDomain" />
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button type="primary" @click="handleClose">Find manifest file</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import customizeIcon from "./assets/icons/customize.png";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      websiteDomain: "",
      customizeIcon,
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";
</style>
