<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="selectedPlugin.icon" alt="" />
            </div>
            <div class="plugin-name">
              <p>{{ selectedPlugin.name }}</p>
              <span>{{ selectedPlugin.provider }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <p>You're about to enable Jira</p>
        <div class="plugin-modal-login">
          <div>
            <p><b>Login to Jira to continue</b></p>
          </div>
          <div style="margin-bottom: 10px">
            <el-input placeholder="Enter your Jira email..." v-model="jiraEmail" />
          </div>
          <div style="margin-bottom: 10px">
            <el-input
              placeholder="Enter your Jira password..."
              type="password"
              v-model="jiraPassword"
            />
          </div>
          <div>
            <span>For more information on how to generate a PAT for Jira, </span>
            <a
              style="color: blue; text-decoration: underline"
              href="https://developer.atlassian.com/cloud/confluence/basic-auth-for-rest-apis/"
              target="_blank"
            >
              click here</a
            >
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <div>
            <p><b>Configuration</b></p>
            <p>Some additional information is required to enable Jira's REST APIs.</p>
          </div>
          <div>
            <el-input
              placeholder='Enter the server url, i.e. "https://<your-domain>.atlassian.net/rest/api/latest/"'
              v-model="jiraServerUrl"
            />
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button type="primary" @click="handleClose">Enable</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      jiraEmail: "",
      jiraPassword: "",
      jiraServerUrl: "",
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";
</style>
