<template>
  <div class="outreach-tab-contacts">
    <el-row v-if="metrics.length > 0" type="flex">
      <el-card v-for="metric in metrics" :key="metric.id" class="metric" :style="cardStyle(metric)">
        <div class="metric-value">{{ metric.value }}</div>
        <div class="metric-name">{{ metric.name }}</div>
      </el-card>
    </el-row>
    <TabContactsHeader
      @refetch-contact="fetchContacts"
      @apply-filter="onApplyFilter"
      @apply-sort="onApplySort"
      @apply-filter-date="onApplyFilterDate"
      @refetch-data="fetchContacts"
    />
    <div class="outreach-tab-contacts-table" style="margin-top: 20px">
      <el-table
        :data="contactsArray.data"
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        header-row-class-name="outreach-table-header"
        border
        stripe
        class="auto-width-table"
      >
        <el-table-column sortable label="ID" prop="id" min-width="80"> </el-table-column>
        <el-table-column sortable label="First Name" prop="firstName" min-width="170">
          <template slot-scope="props">
            {{ handleNameContact(props.row.firstName) }}
          </template>
        </el-table-column>
        <el-table-column sortable label="Last Name" prop="lastName" min-width="170">
          <template slot-scope="props">
            {{ handleNameContact(props.row.lastName) }}
          </template>
        </el-table-column>
        <el-table-column label="Primary Contact" min-width="170">
          <template slot-scope="props">
            <div class="contact-item-primary-contact">
              <IconContact :channel="props.row.channel" v-if="props.row.channel" />
              <p style="margin-right: 5px">{{ handlePrimaryContact(props.row.primaryContact) }}</p>
              <div class="contact-item-primary-contact-duplicate" v-if="props.row.isDuplicate">
                <el-tooltip class="item" effect="dark" content="Duplicate contact" placement="top">
                  <img :src="DuplicateIcon" alt="" />
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Tags" min-width="170">
          <template slot-scope="props">
            <el-tag
              style="margin: 5px"
              v-for="(tag, index) in props.row.tags"
              :key="index"
              type="info"
              >{{ tag.name }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column sortable label="Stage" min-width="200" alt="stage">
          <template slot-scope="props">
            <!-- Select box to change the Stage -->
            <el-select
              v-model="updateContact[props.row.id].stage"
              @change="confirmChangeStage(props)"
            >
              <el-option
                v-for="item in contactsStages.data"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column sortable label="Country" prop="country" min-width="170"> </el-table-column>
        <el-table-column v-for="(item, i) in labelAttributes" :key="i" min-width="150">
          <template slot="header">
            <el-tooltip :content="item" placement="top">
              <span>{{ item }}</span>
            </el-tooltip>
          </template>
          <template slot-scope="props">
            {{ handleAttributeValue(props.row.contactattributes, item) }}
          </template>
        </el-table-column>
        <el-table-column label="Actions" min-width="170" fixed="right">
          <template slot-scope="props">
            <div class="contact-action" :class="deletingContactId === props.row.id && 'active'">
              <el-tooltip class="item" effect="dark" content="Delete contact" placement="top">
                <el-button
                  type="danger"
                  size="small"
                  @click="deleteContact(props.row)"
                  style="margin: 0px"
                  icon="el-icon-delete"
                >
                </el-button>
              </el-tooltip>
              <el-button
                type="primary"
                size="small"
                @click="openContactDetailDialog(props.row.id)"
                style="margin: 0px 5px"
                icon="el-icon-edit"
              >
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        title="Edit Contact"
        :visible.sync="contactDetailDialog"
        width="40%"
        append-to-body
        v-if="contactDetailDialog"
        top="5vh"
        custom-class="contact-detail-dialog"
        :before-close="cancelEditContact"
      >
        <div class="form-contact">
          <el-form label-position="top">
            <el-row :gutter="5" style="padding: 10px">
              <el-col :span="24">
                <el-form-item>
                  <p>First Name</p>
                  <el-input v-model="updateContact[currentSelectedContactId].firstName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item>
                  <p>Last Name</p>
                  <el-input v-model="updateContact[currentSelectedContactId].lastName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item>
                  <p>Primary Contact</p>
                  <el-input
                    v-model="updateContact[currentSelectedContactId].primaryContact"
                    @input="validatePrimaryContact"
                  >
                  </el-input>
                  <p v-show="errors.primaryContact" class="error-input">
                    {{ errors.primaryContact }}
                  </p>
                </el-form-item></el-col
              >
              <el-col :span="24">
                <el-form-item>
                  <div>
                    <p>Tags</p>
                    <el-select
                      v-model="updateContact[currentSelectedContactId].tags"
                      multiple
                      placeholder=""
                      class="outreach-tab-contacts-table-select"
                    >
                      <el-option
                        v-for="(item, i) in contactsTags.data"
                        :key="i"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </div>

                  <!-- <el-input
                    :value="joinListTag(updateContact[currentSelectedContactId].tags)"
                  ></el-input>  -->
                </el-form-item></el-col
              >
              <el-col :span="24"
                ><el-form-item>
                  <div>
                    <p>Stage</p>
                    <el-select
                      v-model="updateContact[currentSelectedContactId].stage"
                      collapse-tags
                      placeholder="Select"
                      class="outreach-tab-contacts-table-select"
                    >
                      <el-option
                        v-for="(item, i) in contactsStages.data"
                        :key="i"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </div>

                  <!-- <el-input :value="updateContact[currentSelectedContactId].stage"></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item>
                  <p>Country</p>
                  <el-input v-model="updateContact[currentSelectedContactId].country"></el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="24"
                v-for="item in contactAttributes"
                :key="`attribute-${currentSelectedContactId}-${item.id}`"
              >
                <el-form-item>
                  <p v-if="item.type === ATTRIBUTE_TYPE.TEXT_BOX">
                    {{ item.label }}
                    <el-input
                      v-model="attributeContact[`${currentSelectedContactId}-${item.id}`].value"
                    ></el-input>
                  </p>
                  <div v-else-if="item.type === ATTRIBUTE_TYPE.CHECKBOX">
                    <div>{{ item.label }}</div>
                    <div>
                      <el-checkbox
                        :checked="
                          attributeContact[
                            `${currentSelectedContactId}-${item.id}`
                          ]?.value?.toLowerCase() === OPTION_CHECKBOX.TRUE.toLowerCase()
                        "
                        @change="
                          (e) =>
                            setAttributeValueCheckbox(e, `${currentSelectedContactId}-${item.id}`)
                        "
                      />
                    </div>

                    <!-- <el-select
                          v-model="attributeContact[`${currentSelectedContactId}-${item.id}`].value"
                          placeholder="Choose your option"
                          size="small"
                          class="outreach-tab-contacts-table-select"
                        >
                          <el-option
                            v-for="item in optionCheckbox"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                        <el-input
                          :value="attributeContact[`${currentSelectedContactId}-${item.id}`].value"
                        ></el-input> -->
                  </div>
                  <div v-else-if="item.type === ATTRIBUTE_TYPE.DROPDOWN">
                    <p>{{ item.label }}</p>
                    <el-select
                      v-model="attributeContact[`${currentSelectedContactId}-${item.id}`].value"
                      placeholder="Choose your option"
                      size="small"
                      class="outreach-tab-contacts-table-select"
                    >
                      <el-option
                        v-for="item in getOptionFromDropDownValue(item.value)"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <!-- <el-input
                      :value="attributeContact[`${currentSelectedContactId}-${item.id}`].value"
                    ></el-input> -->
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelEditContact()">Cancel</el-button>
          <el-button
            :disabled="!checkIsPhoneNumber(updateContact[currentSelectedContactId].primaryContact)"
            :loading="isLoading"
            type="primary"
            @click="submitForm(currentSelectedContact)"
            >Save</el-button
          >
        </span>
      </el-dialog>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        layout="prev, pager, next"
        :total="contactsArray.count"
        style="padding: 10px; padding-left: 0"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CONTACT_UPDATE from "@/graphql/OutReachContactUpdate.graphql";
import { checkIsPhoneNumber } from "@/helperMethods/outreach";
import {
  ATTRIBUTE_TYPE,
  optionCheckbox,
  OPTION_CHECKBOX,
} from "@/helperMethods/outreach/attribute";
import Vue from "vue";
import { mapGetters } from "vuex";
import DuplicateIcon from "../assets/duplicate_icon.svg";
import IconContact from "./IconContact.vue";
import TabContactsHeader from "./TabContactsHeader.vue";
import moment from "moment";
import Color from "color";

export default Vue.extend({
  components: { TabContactsHeader, IconContact },
  data() {
    return {
      multipleSelection: [],
      pagination: {
        pageSize: 25,
        currentPage: 1,
      },
      updateContact: {},
      // icon
      DuplicateIcon,
      filterDate: [],
      filterBoard: [],
      contactSelected: {},
      checkIsPhoneNumber,
      errors: {
        primaryContact: "",
      },
      deletingContactId: "",
      attributeContact: {},
      // attribute helper
      ATTRIBUTE_TYPE,
      optionCheckbox: optionCheckbox(),
      OPTION_CHECKBOX,
      clientTimezone: moment.tz.guess(),
      dateAttribute: [],
      labelAttributes: [],
      sortBoard: [],
      contactDetailDialog: false,
      currentSelectedContactId: null,
      value: "",
    };
  },

  computed: {
    ...mapGetters(["contactsArray", "contactsTags", "contactsStages", "contactAttributes"]),
    watchContact() {
      return `${JSON.stringify(this.pagination)}${JSON.stringify(this.filterDate)}${JSON.stringify(
        this.sortBoard
      )}${JSON.stringify(this.filterBoard)}`;
    },
    currentSelectedContact() {
      return this.contactsArray.data.find((item) => item.id === this.currentSelectedContactId);
    },
    isLoading() {
      return this.$store.state.outreach.isLoading;
    },
    metrics() {
      return Object.values(this.$store.state.outreach.metrics);
    }
  },
  mounted() {
    this.setDateAttribute();
    this.fetchContacts();
    this.insertUpdateContactData();
    this.insertContactAttributeData();
  },
  watch: {
    watchContact: {
      async handler(_, oldValue) {
        await this.fetchContacts();
      },
      deep: true,
    },
    contactsArray: {
      handler() {
        this.insertUpdateContactData();
        this.insertContactAttributeData();
      },
      deep: true,
    },
    contactAttributes: {
      handler() {
        this.insertContactAttributeData();
      },
      deep: true,
    },
  },
  methods: {
    setDateAttribute() {
      const attr = this.$store.state.modules["outreach"].dateAttributes;
      if (attr) {
        this.dateAttribute = attr.split(",");
      }
    },
    setAttributeValueCheckbox(e, key) {
      this.attributeContact[key].value = e ? OPTION_CHECKBOX.TRUE : OPTION_CHECKBOX.FALSE;
    },
    getOptionFromDropDownValue(itemValue) {
      if (!itemValue.length) return [];
      return [
        ...itemValue.map((value) => ({
          label: value,
          value,
        })),
      ];
    },
    deleteContact(contact) {
      this.deletingContactId = contact.id;
      this.$confirm(`This will permanently delete the automation.`, "Warning", {
        confirmButtonClass: "btn-kr-style",
        cancelButtonClass: "btn-cancel",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.deletingContactId = "";
          this.$store.commit("SET_OUTREACH_LOADING", true);
          const self = this;
          this.$apollo
            .mutate({
              mutation: CONTACT_UPDATE,
              variables: {
                contact: { ...contact, isDeleted: true },
              },
              async update(store, { data: { outReachContactAPI } }) {
                self.$notify.success({
                  title: "Success",
                  position: "bottom-right",
                  message: `Successfully deleted the contact.!`,
                });
                await self.fetchContacts();
              },
            })
            .catch((err) => {
              self.fileList = [];
              self.uploadedContactList = [];
              self.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: `Failed to delete the contact.`,
              });
              this.$store.commit("SET_OUTREACH_LOADING", false);
            });
        })
        .catch(() => {
          this.deletingContactId = "";
          // this.$message({
          //   type: "info",
          //   message: `Delete ${this.label} canceled`,
          // });
        });
    },
    validatePrimaryContact(primaryContact) {
      this.errors.primaryContact =
        checkIsPhoneNumber(primaryContact) || !primaryContact
          ? ""
          : "This primary contact is invalid for Whatsapp";
    },
    insertUpdateContactData() {
      this.updateContact = this.contactsArray.data.reduce(
        (updateContact, contact) => ({
          ...updateContact,
          [contact.id]: {
            ...contact,
            tags: contact.tags.map((tag) => tag.name),
            stage: contact.stage.name,
          },
        }),
        {}
      );
    },
    insertContactAttributeData() {
      this.attributeContact = this.contactsArray.data.reduce((updateAttribute, contact) => {
        const attributes = this.contactAttributes.reduce((contactAttributes, attribute) => {
          const attr = contact.contactattributes.find(
            (contactAttr) => contactAttr.id === attribute.id
          );
          let attrValue = attr?.contactattributevalue?.value || "";
          if (this.dateAttribute.includes(attribute.name) && attrValue) {
            attrValue = moment(attrValue).tz(this.clientTimezone).format("YYYY-MM-DD HH:mm:ssZ");
          }
          return {
            ...contactAttributes,
            [`${contact.id}-${attribute.id}`]: {
              name: attribute.name,
              value: attrValue,
              contactId: contact.id,
              attributeId: attribute.id,
              id: attr?.contactattributevalue?.id || undefined,
            },
          };
        }, {});
        return {
          ...updateAttribute,
          ...attributes,
        };
      }, {});
    },
    handleNameContact(name) {
      return name.length > 33 ? `${name.slice(0, 33)}...` : name;
    },
    handlePrimaryContact(primaryContact) {
      return primaryContact.length > 40 ? `${primaryContact.slice(0, 33)}...` : primaryContact;
    },
    async fetchContacts() {
      const { currentPage, pageSize } = this.pagination;
      await this.$store.dispatch("FETCH_OUTREACH_CONTACTS", {
        take: pageSize,
        page: currentPage,
        ...this.filterDate,
        filter: this.filterBoard,
        sort: this.sortBoard,
      });
      this.toggleSelection(this.contactSelected[`page_${this.pagination.currentPage}`]);
      this.setLabelAttributes();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    joinListTagContact(tags) {
      if (tags.length > 3) {
        const countRemain = tags.length - 3;
        return `${[...tags]
          .splice(0, 3)
          .map(({ name }) => name)
          .join(", ")} & ${countRemain} more`;
      }
      return tags.map((tag) => tag.name).join(", ");
    },
    joinListTag(tags) {
      if (!tags || tags.length === 0) return "";
      return tags.join(", ");
    },
    limiter(e) {
      if (e.length > 3) {
        e.pop();
      }
    },
    getRemainTags(oldTags) {
      return this.contactsTags.data.filter((tag) => {
        const condition = oldTags.some((oldTag) => oldTag.name === tag.name);
        return !condition;
      });
    },
    async submitForm(form) {
      const updateAttributes = Object.entries(this.attributeContact)
        .filter(([key, _]) => key.includes(`${form.id}-`))
        .filter(([_, value]) => value.value)
        .map(([_, value]) => {
          let attrValue = value.value.trim();
          if (this.dateAttribute.includes(value.name) && attrValue && moment(attrValue).isValid()) {
            attrValue = moment(attrValue).toISOString();
          } else if (this.dateAttribute.includes(value.name)) {
            attrValue = "";
          }

          return {
            ...value,
            value: attrValue,
          };
        });
      this.$store.commit("SET_OUTREACH_LOADING", true);
      const { tags = [], stage = "" } = this.updateContact[form.id];
      const updateContact = { ...this.updateContact[form.id] };
      updateContact.tags = [...this.contactsTags.data.filter((tag) => tags.includes(tag.name))];
      // if (tags.length > 0) {
      // } else {
      //   updateContact.tags = form.tags;
      // }
      if (stage) {
        updateContact.stage = this.contactsStages.data.find(
          (tag) => tag.name === updateContact.stage
        );
      } else {
        updateContact.stage = form.stage;
      }
      const self = this;
      await this.$apollo
        .mutate({
          mutation: CONTACT_UPDATE,
          variables: {
            contact: updateContact,
            attributes: updateAttributes,
          },
          async update(store, { data: { outReachContactAPI } }) {
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully update the attribute values.!`,
            });
            self.fetchContacts();
            self.$store.dispatch("FETCH_CONTACT_ATTRIBUTES");
            self.contactDetailDialog = false;
            self.currentSelectedContactId = null;
          },
        })
        .catch((err) => {
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to update the attribute values!`,
          });
          this.$store.commit("SET_OUTREACH_LOADING", false);
          this.insertUpdateContactData();
          this.insertContactAttributeData();
        });
      await this.$apollo
        .mutate({
          mutation: CONTACT_UPDATE,
          variables: {
            contact: updateContact,
            attributes: updateAttributes,
          },
          async update(store, { data: { outReachContactAPI } }) {
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully updated the contact!`,
            });
            await self.$store.dispatch("FETCH_CONTACT_ATTRIBUTES");
            await self.fetchContacts();
          },
        })
        .catch((err) => {
          self.fileList = [];
          self.uploadedContactList = [];
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to update contact!`,
          });
          this.$store.commit("SET_OUTREACH_LOADING", false);
          this.insertUpdateContactData();
          this.insertContactAttributeData();
        });
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
    },
    handleCurrentChange(val) {
      const key = `page_${this.pagination.currentPage}`;
      this.contactSelected[key] = this.multipleSelection;
      this.pagination.currentPage = val;
    },
    onApplyFilter(filterList) {
      this.filterBoard = filterList;
    },
    onApplySort(sortAttribute) {
      this.sortBoard = sortAttribute;
    },
    onApplyFilterDate(filterDate) {
      const [startDate, endDate] = filterDate;
      this.filterDate = { startDate, endDate };
    },
    toggleSelection(rows) {
      if (rows) {
        this.$refs.multipleTable.clearSelection();
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    setLabelAttributes() {
      this.labelAttributes = [];
      this.contactAttributes.forEach((entity) => {
        if (entity.isShowOnClient && !entity.isDeleted) {
          this.labelAttributes.push(entity.name.charAt(0).toUpperCase() + entity.name.slice(1));
        }
      });
    },
    handleAttributeValue(row, name) {
      const attribute = _.find(
        row,
        (entity) =>
          entity.name.toLowerCase() === name.toLowerCase() &&
          entity.isShowOnClient &&
          !entity.isDeleted
      );
      if (!attribute) {
        return "";
      }
      let value = attribute.contactattributevalue.value;
      if (this.dateAttribute.includes(name.toLowerCase()) && value) {
        value = moment(value).tz(this.clientTimezone).format("YYYY-MM-DD HH:mm:ssZ");
      }
      return value;
    },
    openContactDetailDialog(id) {
      this.currentSelectedContactId = id;
      this.contactDetailDialog = true;
    },
    cancelEditContact() {
      this.insertUpdateContactData();
      this.insertContactAttributeData();
      this.contactDetailDialog = false;
    },
    async confirmChangeStage(props) {
      try {
        const confirmed = await this.$confirm("This will change the Stage. Continue?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "info",
        });
        if (confirmed) {
          this.submitForm(props.row);
        }
      } catch (error) {
        const contactArr = this.contactsArray.data;
        const contact = contactArr.find((a) => (a.id = props.row.id));
        this.$set(this.updateContact[props.row.id], "stage", contact.stage.name);
      }
    },
    cardStyle(metric) {
      return {
        "border": 1,
        "border-color": metric.color,
        "background-color": Color(metric.color).lighten(0.9),
      };
    },
  },
});
</script>

<style lang="scss">
$primary-KR-color: #1d57d8;
$row-bg-hover: #f4f7fa;
$base-date-picker-color: #1d57d8;
$button-bg-hover: #e8eefb;
$button-border-hover: #bbcdf3;
.contact-action {
  text-align: center;
  .btn-delete-contact {
    border: none;
    background: none !important;
    &:hover {
      background: none !important;
      path {
        stroke: #1d57d8;
      }
    }
  }
}
.btn-cancel {
  color: $primary-KR-color !important;
  border-color: $primary-KR-color !important;
  &:hover {
    border-color: $primary-KR-color !important;
  }
}

.btn-kr-style {
  background: $primary-KR-color !important;
  border-color: $primary-KR-color !important;
  color: white !important;
  &:hover {
    background: #4573d7 !important;
    border-color: $primary-KR-color !important;
    color: white !important;
  }
}
.outreach-tab-contacts {
  font-family: "Montserrat" !important;
  font-size: 15px;
  padding: 0 10px;
  .el-select-dropdown .el-popper {
    left: 130px !important;
  }

  &-table {
    .form-contact {
      p {
        margin: 0;
      }
    }
    .el-form-item {
      margin-bottom: 10px;
    }
    .error-input {
      margin: 0;
      position: absolute;
      padding: 0 5px;
      color: red;
    }
    .inline-form {
      display: flex;
      justify-content: space-between;
      > div {
        width: 33%;
      }
    }
    &-select {
      width: 200px;
    }

    .el-table tr {
      padding: 18px 0;
      &.expanded {
        background: #ececec !important;
        .el-icon {
          display: block !important;
        }
      }

      &:hover {
        .el-table__expand-icon > .el-icon {
          display: block !important;
        }
        .contact-action {
          display: block;
        }
      }
    }
    .el-table {
      border: 1px solid #dcdfe6;
      td {
        padding: 2px 0;
      }
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        border-color: white !important;
      }
    }
    .el-table td,
    .el-table th,
    .el-table th > .cell,
    .el-table-column--selection .cell,
    .el-table .cell {
      color: black;
      font-size: 14px;
      border-color: #dcdfe6;
    }

    .el-table-column--selection .cell {
      padding: 0 !important;
    }
    .el-table th,
    .el-table th > .cell {
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.contact-item-tag {
  margin: 0;
  word-break: break-word;
  width: 80%;
}

.contact-item-primary-contact {
  display: flex;
  align-items: center;
  &-icon {
    font-size: 30px;
    margin-right: 10px;
  }
  &-duplicate {
    margin: 5px 0 0 10px;
  }
}

.outreach-table-header {
  th {
    background: #e8eefb !important;
    border-bottom: 1px solid #dcdfe6 !important;
    .cell {
      font-size: 14px !important;
      color: $primary-KR-color !important;
    }
  }
}
// Write css to set height of the dialog is 500px and will scroll when the content is overflow
.contact-detail-dialog {
  .el-dialog__body {
    height: 500px;
    overflow-y: auto;
  }
  .form-contact {
    p {
      margin: 0;
    }
    .el-form-item {
      margin-bottom: 10px;
    }

    .el-input {
      margin-top: 10px;
    }
  }
  .error-input {
    color: red;
  }
}

.outreach-tab-contacts-table-select {
  width: 70%;
  .el-select__tags {
    padding: 10px;

    .el-tag {
      margin-bottom: 10px;
    }
  }
}

table th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(6)) > .cell {
  margin-bottom: 3px;
}

.metric {
  background-color: cyan;
  margin-right: 16px;
  height: 96px;
  width: 176px;
}

.metric-value {
  font-size: 32px;
}

.metric-name {
  color: grey;
}
</style>
