<template>
  <iframe
    :src="settings.metabase.url"
    frameborder="0"
    width="100%"
    height="100%"
    allowtransparency
  ></iframe>
</template>

<script>
  export default {
    computed: {
      settings() {
        return this.$store.state.modules.ecare;
      },
    },
  }
</script>